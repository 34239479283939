import Icon from 'components/commons/Icon'
import { Link } from 'components/commons/Link'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import Facebook from '/public/assets/img/icons/facebook.svg'
import Linkedin from '/public/assets/img/icons/linkedin.svg'
import Youtube from '/public/assets/img/icons/youtube.svg'
import Logo from '/public/assets/img/visualorcwhite.svg'

export const Footer = (): JSX.Element => {
  const t = useTranslations('layout/footer')

  const links = [
    { name: t('link1'), to: '/download', id: 1 },
    { name: t('link2'), to: '/planos-e-precos', id: 2 },
    { name: t('link3'), to: '/software', id: 4 },
    { name: t('link4'), to: '/termos-e-condicoes', id: 5 },
    { name: t('link5'), to: '/politica-de-privacidade', id: 6 }
  ]

  let mailTo = 'mailto:' + t('detailEmail')
  let telTo = 'tel:' + t('detailPhoneNumber')

  return (
    <footer className='relative  overflow-y-hidden bg-black'>
      <div className='absolute'>
        {/* <Image src={Shape} alt='Shape' priority={false} className="-z-20 left-0 opacity-60" /> */}
      </div>
      <div className='h-px bg-gradient-to-r from-transparent via-blue-darker to-transparent mb-16' />
      <div className='relative w-full h-full mx-auto md:max-w-3xl px-4 lg:max-w-6xl'>
        <div className='grid grid-cols-1 gap-y-8 gap-x-20 pb-8 md:grid-cols-6 lg:grid-cols-4'>

          <div className='md:col-span-4 lg:col-span-1'>
            <Image
              src={Logo}
              alt='VisualOrc'
              width={160}
              className='mb-8'
              priority={false}
            />
            {/* <p className='text-white'>{t('newsletter')}</p> */}
            {/* <NewsletterForm /> */}
          </div>

          <div className='md:col-span-2 lg:col-span-1'>
            <label className='text-sm text-gray-900 font-extrabold uppercase'>{t('links')}</label>
            <ul>
              {links.map(({ name, to, id }: any) => (
                <li key={id} className='my-4'>
                  <Link
                    href={to}
                    className='text-md text-white font-semibold hover:text-blue'
                    prefetch={false}
                  >
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className='md:col-span-2 lg:col-span-1'>
            <label className='text-sm text-gray-900 font-extrabold uppercase'>
              {t('contacts')}
            </label>
            <ul>
              <li className='flex my-4'>
                <div>
                  <strong className='text-white'>{t('email')}:</strong>
                  <br />
                  <Link
                    href={mailTo}
                    className='font-semibold text-white'
                    text={t('detailEmail')}
                    prefetch={false}
                  />
                </div>
              </li>
              <li className='flex'>
                {/* <Icon
                  icon={faPhone}
                  size='xl'
                  className='mr-4 mt-1 text-blue'
                /> */}
                <div>
                  <strong className='text-white'>{t('phoneNumber')}:</strong>
                  <br />
                  <Link
                    href={telTo}
                    className='font-semibold text-white'
                    text={t('detailPhoneNumber')}
                    prefetch={false}
                  />
                </div>
              </li>
            </ul>
          </div>

          <div className='md:col-span-2 lg:col-span-1'>
            <div className='flex -ml-2 mt-4 space-x-4'>
              <Link href="https://www.facebook.com/visorc/" target="_blank">
                <Icon src={Facebook} alt='facebook' />
              </Link>
              <Link href="https://www.linkedin.com/company/visualorc" target="_blank">
                <Icon src={Linkedin} alt='linkedin' />
              </Link>
              <Link href="https://www.youtube.com/@visualorcsoftware" target="_blank">
                <Icon src={Youtube} alt="youtube" />
              </Link>
            </div>
          </div>

        </div>
        {/* <Divider variant='secondary' /> */}
        <div className='mt-2'>
          <p className='mt-0 font-semibold text-white text-center text-sm'>
            &copy; Copyright 2024. All Rights Reserved by VISUALORC
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
