import * as NextLink from 'next/link'
import { LinkProps as NextLinkProps } from 'next/link'
import { ForwardedRef, forwardRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type LinkProps = {
  href: string
  children?: ReactNode | undefined
  text?: string | undefined
  className?: string | undefined
  variant?: 'primary' | 'secondary' | 'standard' | 'plain'
  submitted?: boolean | undefined
  prefetch?: boolean
  target?: string
} & Omit<NextLinkProps, 'className'>

export const Link = forwardRef(function Link(
  {
    href,
    children,
    text,
    variant,
    submitted,
    prefetch = false,
    target,
    className,
    ...passthroughProps
  }: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return (
    <NextLink.default
      ref={ref}
      href={href}
      prefetch={prefetch}
      target={target}
      {...passthroughProps}
      className={twMerge(
        'text-md font-extrabold tracking-wider hover:text-blue',
        variant === 'primary' && 'text-blue hover:underline font-bold',
        variant === 'secondary' && 'bg-white text-blue',
        variant === 'standard' && 'bg-white border-2 border-blue text-blue',
        className
      )}
    >
      {text ? text : children}
    </NextLink.default>
  )
})
