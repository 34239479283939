import { useEffect, useState } from 'react'

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [scrollDirection, setScrollDirection] = useState(1)
  const threshold = 50;

  useEffect(() => {

    let previousScrollYPosition = window.scrollY;

    const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
      Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

    const isScrollingUp = (currentScrollYPosition: number) =>
      currentScrollYPosition > previousScrollYPosition &&
      !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
      !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

    // const updatePosition = () => {
    //   scrollPosition + 100 > window.scrollY && setScrollDirection()
    //   setScrollPosition(window.scrollY)

    // }
    const updateScrollDirection = () => {
      const currentScrollYPosition = window.scrollY;

      if (scrolledMoreThanThreshold(currentScrollYPosition)) {
        const newScrollDirection = isScrollingUp(currentScrollYPosition)
          ? 1
          : 0;
        setScrollDirection(newScrollDirection);
        previousScrollYPosition =
          currentScrollYPosition > 0 ? currentScrollYPosition : 0;
      }
      setScrollPosition(window.scrollY)
    };

    const onScroll = () => window.requestAnimationFrame(updateScrollDirection);
    window.addEventListener('scroll', onScroll)

    // updatePosition()
    return () => window.removeEventListener("scroll", onScroll);
    // return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return { position: scrollPosition, direction: scrollDirection }
}