'use client'
import Icon from 'components/commons/Icon'
import { motion } from "framer-motion"
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import English from '/public/assets/img/language/en.svg'
import Spanish from '/public/assets/img/language/es.svg'
import French from '/public/assets/img/language/fr.svg'
import Portuguese from '/public/assets/img/language/pt.svg'

export default function LangSwitcher() {

  const router = useRouter()
  const { pathname, asPath, query, locale } = router
  const [isMouse, toggleMouse] = useState(false);

  const toggleMouseMenu = () => {
    toggleMouse(!isMouse);
  };

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.5
      },
      display: "block"
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: "none"
      }
    }
  };

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleMouse(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const switchLanguage = (lang: false | string) => {
    return router.push({ pathname, query }, asPath, { locale: lang })
  }

  function Flag({ src, alt, className, lang }: any) {
    return (
      <div className={className} onClick={() => switchLanguage(lang)}>
        <Icon src={src} alt={alt} width={28} height={28} />
      </div>
    )
  }
  return (
    <div className='cursor-pointer'
      onClick={toggleMouseMenu}
      ref={wrapperRef}
    >
      <div className="flex lg:flex-col">
        <motion.div className='flex'>
          {!locale || locale === "pt" && <Icon src={Portuguese} alt='Portuguese' width={28} height={20} />}
          {locale === "en" && <Icon src={English} alt='English' width={28} height={20} />}
          {locale === "es" && <Icon src={Spanish} alt='Spanish' width={28} height={20} />}
          {locale === "fr" && <Icon src={French} alt='French' width={28} height={20} />}
        </motion.div>
        <motion.div
          initial="enter"
          animate={isMouse ? "enter" : "exit"}
          variants={subMenuAnimate}
          className='absolute top-12'
        >
          <div className="-mb-1.5 pb-3.5 space-y-4">
            {isMouse && locale !== "pt" &&
              <Flag src={Portuguese} alt='Portuguese' lang="pt" />
            }
            {isMouse && locale !== "en" &&
              <Flag src={English} alt='English' lang="en" />
            }
            {isMouse && locale !== "es" &&
              <Flag src={Spanish} alt='Spanish' lang="es" />
            }
            {isMouse && locale !== "fr" &&
              <Flag src={French} alt='French' lang="fr" />
            }
          </div>
        </motion.div>
      </div>
    </div >
  );
}