import React from 'react'
import { ForwardedRef, forwardRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { Merge } from '../../src/utils/types'
import dynamic from 'next/dynamic'
import Icon from './Icon'
const Spinner = dynamic(() => import("./Spinner"), {
  ssr: false,
})
import * as CheckCircle from "public/assets/img/icons/check-circle.svg"

export interface CoreButtonProps {
  children?: ReactNode
  className?: string | undefined
  variant?: 'primary' | 'secondary' | 'standard' | 'plain' | undefined
  isSubmitting?: boolean | undefined
  submitted?: boolean | undefined
  text?: string | undefined
}

type ButtonProps = Merge<React.ComponentProps<'button'>, CoreButtonProps>

export const Button = forwardRef(function Button(
  { variant, submitted, className, children, text, isSubmitting = false, ...passthroughProps }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      type='button'
      disabled={isSubmitting}
      {...passthroughProps}
      className={twMerge(
        variant && 'text-lg shadow-md py-3 px-5 font-bold border-2 tracking-wide',
        variant === 'primary' && 'bg-blue border-blue text-white',
        variant === 'secondary' && 'bg-black text-white border-black',
        variant === 'standard' && 'bg-white border-blue text-blue',
        submitted && variant === 'standard' && 'bg-green border-green text-white',
        // submitted && variant === 'secondary' && 'bg-blue border-white text-white',
        className
      )}
    >
      {text ? (
        <div className='flex w-full h-full justify-center'>
          {text}
          <div className='relative justify-end'>
            {isSubmitting && <Spinner className='inline-block ml-2 h-6 w-6 border-4' />}
            {submitted && <Icon alt='submitted' src={CheckCircle} className='inline-block ml-2 h-7 w-7 border-4 mb-1'></Icon>}
          </div>
        </div>
      ) : children}
    </button>
  )
})
