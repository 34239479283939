import { Button } from 'components/commons/Button'
import { Link } from 'components/commons/Link'
import { useScrollPosition } from 'hooks/ScrollPosition'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'
import Logo from '/public/assets/img/visualorcwhite.svg'
import LangSwitcher from './LangSwitcher'

const SideMenu = dynamic(() => import("./SideMenu"), {
  ssr: false,
})

type TopBarProps = {
  variant?: 'primary' | 'secondary'
}

export default function TopBar({ variant = 'primary' }: TopBarProps) {
  const t = useTranslations('layout/topbar')
  const scroll = useScrollPosition()

  const links = [
    { name: t('features'), to: '/software', id: 1 },
    { name: t('pricing'), to: '/planos-e-precos', id: 2 },
    // { name: t('blog'), to: '/blog', id: 3 },
    { name: t('contact-us'), to: '/contatos', id: 4 },
    // { name: t('account'), to: '/conta', id: 5 },
  ]

  const linksSideMenu = [
    { name: t('home'), to: '/', id: 1 },
    { name: t('features'), to: '/software', id: 2 },
    { name: t('pricing'), to: '/planos-e-precos', id: 3 },
    // { name: t('blog'), to: '/blog', id: 4 },
    { name: t('contact-us'), to: '/contatos', id: 5 },
    // { name: t('account'), to: '/conta', id: 6 },
  ]

  return (
    <nav
      className={twMerge(
        'fixed w-full top-0 text-center z-50 lg:shadow-none',
        variant === "primary" && scroll.position < 100 && 'block bg-transparent',
        variant === "primary" && scroll.direction && scroll.position > 100 && 'hidden',
        variant === "primary" && !scroll.direction && scroll.position >= 100 && 'block bg-black',
        variant === "secondary" && 'bg-black'
      )}
    >
      <div className='flex w-full max-w-7xl mx-auto items-center justify-between px-4 pt-4 pb-3'>
        <Link
          href='/'
          className='inline-block float-left bg-transparent'
          prefetch={false}
        >
          <Image alt={t("logo-image-description")} src={Logo} width={150} height={29} />
        </Link>
        <div className='flex justify-end'>
          <div className='flex items-center space-x-12 mr-6'>
            <div className='hidden lg:flex'>
              {links.map(({ name, to, id }: any) => (
                <div key={id} className='inline-flex ml-8 h-full'>
                  <Link className='uppercase text-sm h-full text-white' href={to} text={name} prefetch={false} />
                </div>
              ))}
              <div className='relative flex ml-6 mr-2'>
                <LangSwitcher />
              </div>
            </div>
          </div>
          <div className='hidden lg:inline-block cursor-pointer'>
            <Link href="/download" prefetch={false}>
              <Button
                variant='primary'
                className='inline-flex'
              >
                {t('download')}
              </Button>
            </Link>
          </div>
          <SideMenu links={linksSideMenu} />
        </div>
      </div>
      <div className='h-px bg-gradient-to-r from-transparent via-blue-darker to-transparent ' />
    </nav>
  )
}