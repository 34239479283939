import { ReactNode } from 'react';
import TopBar from './Topbar';

type LayoutProps = {
  variant?: 'primary' | 'secondary'
  children: ReactNode
}

export default function Layout({ children, variant }: LayoutProps) {

  return (
    <div>
      <header>
        <TopBar variant={variant} />
      </header>
      <main>
        {children}
      </main>
    </div>
  )
}
